import { Grid, GridProps } from '@chakra-ui/layout';
import LAYOUT from './layoutConstants';

// Implementation of the Grid system for the site. This is the base layout that all other layouts should extend from.

export interface BaseLayoutProps extends GridProps {
  includeTopPadding?: boolean;
  includeBottomPadding?: boolean;
}

const BaseLayout: React.FC<BaseLayoutProps> = ({
  children,
  includeTopPadding = false,
  includeBottomPadding = true,
  ...rest
}) => {
  return (
    <Grid
      columnGap={LAYOUT.COLUMN_GAP}
      // Two columns on mobile, three columns on desktop.
      gridTemplateColumns={{
        base: LAYOUT.MOBILE_TEMPLATE_COLUMNS,
        xl: LAYOUT.DESKTOP_TEMPLATE_COLUMNS,
      }}
      mx="auto"
      // Padding on the top and bottom of the page.
      pt={
        includeTopPadding
          ? { base: LAYOUT.MOBILE_TOP_PADDING, xl: LAYOUT.DESKTOP_TOP_PADDING }
          : undefined
      }
      pb={
        includeBottomPadding
          ? { base: LAYOUT.MOBILE_TOP_PADDING, xl: LAYOUT.DESKTOP_TOP_PADDING }
          : undefined
      }
      w="100%"
      {...rest}
    >
      {children}
    </Grid>
  );
};

export default BaseLayout;
