import { GridItem, GridItemProps } from '@chakra-ui/layout';
import BaseLayout, { BaseLayoutProps } from './BaseLayout';

interface CompoundComponents {
  Main: typeof MainContent;
}

export type MainContentProps = GridItemProps;

const MainContent: React.FC<GridItemProps> = ({ children, ...rest }) => {
  return (
    <GridItem as="main" colSpan={{ base: 2, xl: 3 }} {...rest}>
      {children}
    </GridItem>
  );
};

const FullWidthLayout: React.FC<BaseLayoutProps> & CompoundComponents = ({
  children,
  ...rest
}) => {
  return <BaseLayout {...rest}>{children}</BaseLayout>;
};

FullWidthLayout.Main = MainContent;

export default FullWidthLayout;
