import LAYOUT from '@components/Layouts/layoutConstants';
import { z as zod } from 'zod';

const pixelParser = zod
  .custom<`${number}px`>((val) => {
    return /^\d+px$/.test(val as string);
  })
  .optional();

const paramsParser = zod.object({
  mobile: pixelParser,
  desktop: pixelParser,
});

export const getContainerSpacing = (mobile?: string, desktop?: string) => {
  const parsingResult = paramsParser.safeParse({ mobile, desktop });

  if (!parsingResult.success) {
    console.error('getContainerSpacing: Invalid params.', { mobile, desktop });
    return null;
  }

  const mobileSpacing =
    parsingResult.data.mobile || LAYOUT.CONTAINER_MOBILE_SPACING;
  const desktopSpacing =
    parsingResult.data.desktop || LAYOUT.CONTAINER_DESKTOP_SPACING;

  return {
    sx: {
      '&': {
        '--container-spacing': { base: mobileSpacing, xl: desktopSpacing },
      },
    },
  };
};
