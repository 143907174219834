import {
  AccordionPanelProps,
  AccordionProps,
  CardBodyProps,
} from '@chakra-ui/react';

const accordion: Record<string, AccordionProps> = {
  'seniorhomes.com': {
    background: 'secondary.50',
  },
  default: {
    background: 'white',
  },
};

const accordionPanel: Record<string, AccordionPanelProps> = {
  'seniorhomes.com': {
    paddingBottom: 6,
  },
  default: {
    paddingTop: 0,
    paddingBottom: 4,
  },
};

const cardBody: Record<string, CardBodyProps> = {
  'seniorhomes.com': {
    p: 0,
  },
  default: {
    p: 3,
  },
};

const VerticalTableOfContentsStyles = {
  accordion,
  accordionPanel,
  cardBody,
};

export default VerticalTableOfContentsStyles;
