import FullWidthLayout from '@components/Layouts/FullWidthLayout';
import EditableArea from '@components/LayoutStructure/EditableArea';
import Breadcrumb from '@components/Navigation/Breadcrumb';
import { HorizontalTableOfContents } from '@components/TableOfContents';
import { getContainerSpacing } from '@utils/layout';

interface OneColumnPageProps {
  title: string;
  main: any;
  enableTOC: boolean;
  advertisingDisclosure?: string;
  enableBreadcrumb?: boolean;
  includeTopPadding?: boolean;
}

const OneColumnPage = (props: OneColumnPageProps) => {
  const {
    main,
    enableTOC,
    enableBreadcrumb,
    includeTopPadding,
    advertisingDisclosure,
  } = props;

  return (
    <FullWidthLayout includeTopPadding={includeTopPadding}>
      <FullWidthLayout.Main>
        {enableTOC ? <HorizontalTableOfContents /> : null}
        {enableBreadcrumb && (
          <Breadcrumb advertisingDisclosure={advertisingDisclosure} />
        )}
        {main && <EditableArea content={main} {...getContainerSpacing()} />}
      </FullWidthLayout.Main>
    </FullWidthLayout>
  );
};

export default OneColumnPage;
