import { Box } from '@chakra-ui/layout';
import {
  getPageHeadings,
  PageContext,
} from '@components/LayoutStructure/EditablePage';
import useProviderFallback from '@hooks/use-provider-fallback';
import { createID } from '@utils/strings';
import { useContext, useEffect, useRef, useState } from 'react';
import StoryContext from '~/contexts/StoryContext';
import { TableOfContentsItem } from './TableOfContentsItem';
import useTranslation from '@hooks/use-translation';
import SiteContext from '~/contexts/SiteContext';
import { Domain } from '~/types/Domains';

const HorizontalTableOfContents: React.FC = () => {
  const pageContext = useContext(PageContext);
  const [activeHeading, setActiveHeading] = useState('');
  const flexRef = useRef<null | HTMLDivElement>(null);
  const { provider, status } = useProviderFallback();
  const storyContext = useContext(StoryContext);
  const { t } = useTranslation();
  const siteContext = useContext(SiteContext);
  const domain = siteContext.site?.path as Domain;
  const pageHeadings = getPageHeadings(
    pageContext,
    storyContext,
    provider,
    status,
    domain,
    t
  );
  const checkIntersecting = (entries) => {
    const [entry] = entries;
    if (entry.isIntersecting) {
      setActiveHeading(entry.target.innerHTML);
    }
  };

  useEffect(() => {
    const options = {
      rootMargin: '10% 0% -90% 0%',
    };

    const observer = new IntersectionObserver(checkIntersecting, options);

    pageHeadings?.forEach((title) => {
      const element = document.getElementById(createID(title));
      if (element) {
        observer.observe(element!);
      }
    });

    if (flexRef.current && flexRef.current && pageHeadings) {
      const maxScroll =
        flexRef.current.scrollWidth - flexRef.current.offsetWidth;

      const scrollToPosition = Math.min(
        (flexRef.current.scrollWidth / flexRef.current.childElementCount + 4) *
          pageHeadings.indexOf(activeHeading),
        maxScroll
      );

      flexRef.current.scroll({
        left: scrollToPosition,
        behavior: 'smooth',
      });
    }

    return () => {
      pageHeadings?.forEach((title) => {
        const element = document.getElementById(createID(title));
        if (element) {
          observer.unobserve(element!);
        }
      });
    };
  }, [activeHeading, pageHeadings]);

  if (pageHeadings?.length === 0) return <></>;
  return (
    <Box
      m="auto"
      display="flex"
      position="sticky"
      maxWidth="container.xl"
      top={0}
      backgroundColor="white"
      overflow="scroll"
      sx={{
        '::-webkit-scrollbar': {
          display: 'none',
        },
        scrollbarWidth: 'none',
      }}
      scrollSnapType="x mandatory"
      scrollPadding="16px"
      gap={8}
      ref={flexRef}
      zIndex={10000}
      px={{ base: 4, lg: 8 }} // This is the same spacing as the Container component
    >
      {pageHeadings?.map((title, index) => (
        <a href={`#${createID(title)}`} key={`${title}-${index}`}>
          <Box onClick={() => setActiveHeading(title)} scrollSnapAlign="start">
            <TableOfContentsItem
              title={title}
              activeHeading={title === activeHeading}
            />
          </Box>
        </a>
      ))}
    </Box>
  );
};

export default HorizontalTableOfContents;
