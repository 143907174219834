import { Box, Heading, Link, ListIcon, Text } from '@chakra-ui/layout';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AccordionProps,
  Show,
} from '@chakra-ui/react';
import CardWithLinks from '@components/CardWithLinks';
import Container from '@components/LayoutStructure/Container';
import {
  getPageHeadings,
  PageContext,
} from '@components/LayoutStructure/EditablePage';
import { StringToIconKeys, STRING_TO_ICON_CLASS } from '@components/RenderIcon';
import useProviderFallback from '@hooks/use-provider-fallback';
import { getColor } from '@utils/getColor';
import { createID } from '@utils/strings';
import { useContext, useEffect, useState } from 'react';
import { HeadingElements, HeadingSizes } from '~/@types/heading';
import SiteContext from '~/contexts/SiteContext';
import StoryContext from '~/contexts/StoryContext';
import DomainStyles from './VerticalTableOfContentsStyles';
import useTranslation from '@hooks/use-translation';
import { Domain } from '~/types/Domains';

interface VerticalTableOfContentsProps {
  title?: string;
  headingSize?: HeadingSizes;
  headingElement?: HeadingElements;
  titleAlignment?: 'left' | 'center' | 'right';
  backgroundColor?: string;
  backgroundColorRange?: string;
  linkColor?: string;
  linkColorRange?: string;
  textIcon?: StringToIconKeys;
  includeListStyle?: boolean;
  textDecoration?: string;
  borderRadius?: string;
  boxShadow?: 'lg' | 'md' | 'sm' | 'none';
  displayMobileOnly?: boolean;
  displayDesktopOnly?: boolean;
  displayAccordion?: boolean;
  expandAccordion?: boolean;
}

const VerticalTableOfContents = (props: VerticalTableOfContentsProps) => {
  const [accordionIndex, setAccordionIndex] = useState<
    Exclude<AccordionProps['index'], undefined>
  >([]);
  const pageContext = useContext(PageContext);
  const { provider, status } = useProviderFallback();
  const storyContext = useContext(StoryContext);
  const { t } = useTranslation();
  const siteContext = useContext(SiteContext);
  const domain = siteContext?.site?.path as Domain;
  const pageHeadings = getPageHeadings(
    pageContext,
    storyContext,
    provider,
    status,
    domain,
    t
  );

  const accordionProps =
    DomainStyles.accordion[domain] || DomainStyles.accordion.default;
  const panelProps =
    DomainStyles.accordionPanel[domain] || DomainStyles.accordionPanel.default;
  const cardBodyProps =
    DomainStyles.cardBody[domain] || DomainStyles.cardBody.default;

  const {
    title,
    headingSize,
    headingElement = 'h2',
    titleAlignment = 'left',
    backgroundColor,
    backgroundColorRange,
    linkColor,
    linkColorRange,
    borderRadius,
    boxShadow,
    textDecoration,
    textIcon,
    includeListStyle,
    displayMobileOnly,
    displayDesktopOnly,
    displayAccordion,
    expandAccordion,
  } = props;

  useEffect(() => {
    if (window.location.hash) {
      setAccordionIndex([0]);
    }
    if (expandAccordion) {
      setAccordionIndex([0]);
    }
  }, [expandAccordion]);

  if (pageHeadings?.length === 0) return <></>;

  const tocLinks = (
    <CardWithLinks
      cardProps={{ p: 0, variant: 'unstyled', bg: 'unset' }}
      cardBodyProps={{
        borderRadius: 8,
        background: getColor(backgroundColor, backgroundColorRange),
        ...cardBodyProps,
      }}
      listProps={{
        spacing: 4,
      }}
      listItemProps={{
        color: getColor(linkColor, linkColorRange),
      }}
      linkProps={{
        color: undefined,
        fontSize: 'md',
        textDecoration,
      }}
      links={pageHeadings.map((title) => ({
        text: title,
        url: `#${createID(title)}`,
      }))}
      renderLink={({ text, url }, linkProps) => (
        <>
          {includeListStyle && (
            <Text mr={2} color={getColor(linkColor, linkColorRange)}>
              •
            </Text>
          )}
          {textIcon && (
            <ListIcon
              as={STRING_TO_ICON_CLASS[textIcon]}
              color={getColor(linkColor, linkColorRange)}
              mt="5px"
            />
          )}
          <Link href={url} textDecoration={textDecoration} {...linkProps}>
            {text}
          </Link>
        </>
      )}
    />
  );

  const getCollapsibleTableOfContents = () => (
    <Container as="section">
      <Accordion
        allowMultiple
        boxShadow={boxShadow}
        borderRadius={borderRadius}
        index={accordionIndex}
        onChange={setAccordionIndex}
        {...accordionProps}
      >
        <AccordionItem border="none">
          <Box as={headingElement}>
            <AccordionButton
              _hover={{}}
              borderRadius={borderRadius}
              justifyContent="space-between"
              p={4}
              px={boxShadow ? 4 : 0}
            >
              <Heading as="span" size={headingSize} textAlign={titleAlignment}>
                {title || 'Table of Contents'}
              </Heading>
              <AccordionIcon boxSize={6} />
            </AccordionButton>
          </Box>

          <AccordionPanel paddingBottom={6} {...panelProps}>
            {tocLinks}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Container>
  );

  const getTableOfContents = () => (
    <Container as="section">
      <Box
        display="flex"
        flexDirection="column"
        backgroundColor="white"
        boxShadow={boxShadow}
        borderRadius={borderRadius}
        p={boxShadow ? 4 : undefined}
      >
        {title && (
          <Heading
            as={headingElement}
            size={headingSize}
            textAlign={titleAlignment}
            mb={4}
          >
            {title}
          </Heading>
        )}
        {tocLinks}
      </Box>
    </Container>
  );

  const getContent = () => {
    if (domain === 'seniorhomes.com') {
      return getCollapsibleTableOfContents();
    }

    if (displayAccordion) {
      return getCollapsibleTableOfContents();
    }

    return getTableOfContents();
  };

  if (displayMobileOnly) {
    return <Show below="lg">{getContent()}</Show>;
  }

  if (displayDesktopOnly) {
    return <Show above="lg">{getContent()}</Show>;
  }
  return getContent();
};

export default VerticalTableOfContents;
